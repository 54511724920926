// setTimeout(function () {
//   $(document).ready(function () {

//     var $status = jQuery('.pagingInfo');
//     var $slickElement = jQuery('.testimonial-slider');

//     $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
//       var i = (currentSlide ? currentSlide : 0) + 1;
//       $status.text(i + '/' + slick.slideCount);
//     });

//     $slickElement.slick({
//       autoplay: true,
//       autoplaySpeed: 5000,
//       centerMode: true,
//       centerPadding: '100px',
//       speed: 1000,
//       draggable: true,
//       infinite: true,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       arrows: true,
//       dots: false,
//       responsive: [{
//           breakpoint: 991,
//           settings: {
//             slidesToShow: 2,
//             slidesToScroll: 1,
//           }
//         },
//         {
//           breakpoint: 575,
//           settings: {
//             slidesToShow: 1,
//             slidesToScroll: 1,
//           }
//         }
//       ]
//     });
//   });
// }, 1000);

// setTimeout(function () {

//   $('.partner-carousel').slick({
//     infinite: true,
//     slidesToShow: 5, // Shows a three slides at a time
//     slidesToScroll: 1, // When you click an arrow, it scrolls 1 slide at a time
//     arrows: false, // Adds arrows to sides of slider
//     dots: false, // Adds the dots on the bottom
//     autoplay: true
//   });

// // addscrolljs
// var controller = new ScrollMagic.Controller();
// new ScrollMagic.Scene({
//     triggerElement: "#trigger1",
//     triggerHook: 0.9, // show, when scrolled 10% into view
//     duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//     offset: 50 // move trigger to center of element
//   })
//   .setClassToggle("#reveal1", "visible") // add class to reveal
//   .addIndicators() // add indicators (requires plugin)
//   .addTo(controller);

//end addscrolljs

// new ScrollMagic.Scene({
//     triggerElement: "#triggerEcoTravelsHeading",
//     triggerHook: 0.9, // show, when scrolled 10% into view
//     duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//     offset: 50 // move trigger to center of element
//   })
//   .setClassToggle("#EcoTravelsHeading", "bounceIn") // add class to reveal
//   .addIndicators() // add indicators (requires plugin)
//   .addTo(controller);

// end

// new ScrollMagic.Scene({
//     triggerElement: "#triggerBest",
//     triggerHook: 0.9, // show, when scrolled 10% into view
//     duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//     offset: 50 // move trigger to center of element
//   })
//   .setClassToggle("#BestFlightHeading", "zoomInLeft") // add class to reveal
//   .addIndicators() // add indicators (requires plugin)
//   .addTo(controller);

// end

// new ScrollMagic.Scene({
//     triggerElement: "#triggerflights",
//     triggerHook: 0.9, // show, when scrolled 10% into view
//     duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//     offset: 50 // move trigger to center of element
//   })
//   .setClassToggle("#flights", "fadeInLeft") // add class to reveal
//   .addIndicators() // add indicators (requires plugin)
//   .addTo(controller);

// end

// var revealElements = document.getElementsByClassName("digit");
// for (var i = 0; i < revealElements.length; i++) { // create a scene for each element
//   new ScrollMagic.Scene({
//       triggerElement: revealElements[i], // y value not modified, so we can use element as trigger as well
//       offset: 50, // start a little later
//       triggerHook: 0.9,
//     })
//     // .setClassToggle(revealElements[i], "visible1") // add class toggle
//     .addIndicators({
//       name: "digit " + (i + 1)
//     }) // add indicators (requires plugin)
//     .addTo(controller);
// }

// init controller
// var controller = new ScrollMagic.Controller();
// var scene = new ScrollMagic.Scene({triggerElement: "#trigger_new"})
//         // trigger animation by adding a css class
//         .setClassToggle("#animate_new", "zap")
//         .addIndicators({name: "1 - add a class"}) // add indicators (requires plugin)
//         .addTo(controller);

// build scene

// var animateElem = document.getElementById("animate_colorchange");
// if (!animateElem) return
// var scene = new ScrollMagic.Scene({
//     triggerElement: "#trigger_colorchange",
//     duration: 100
//   })
//   .on("enter", function () {
//     // trigger animation by changing inline style.
//     animateElem.style.backgroundColor = "#197D36";
//   })
//   .on("leave", function () {
//     // reset style
//     animateElem.style.backgroundColor = "";
//   })
//   .addIndicators({
//     name: "2 - change inline style"
//   }) // add indicators (requires plugin)
//   .addTo(controller);

//---------------------------------------------------//

// var animateElem = document.getElementById("animate_colorchange1");
// if (!animateElem) return;
// var scene = new ScrollMagic.Scene({
//     triggerElement: "#trigger_colorchange1",
//     duration: 100
//   })
//   .on("enter", function () {
//     // trigger animation by changing inline style.
//     animateElem.style.backgroundColor = "#197D36";
//   })
//   .on("leave", function () {
//     // reset style
//     animateElem.style.backgroundColor = "";
//   })
//   .addIndicators({
//     name: "2 - change inline style"
//   }) // add indicators (requires plugin)
//   .addTo(controller);

//---------------------------------------------------//

// // build scene
// new ScrollMagic.Scene({
//     triggerElement: "#trigger_tick",
//     triggerHook: 0.9, // show, when scrolled 10% into view
//     duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//     offset: 50 // move trigger to center of element
//   })
//   .setClassToggle("#animate_tick", "wobble") // add class to reveal
//   .addIndicators() // add indicators (requires plugin)
//   .addTo(controller);

// // build scene
// new ScrollMagic.Scene({
//     triggerElement: "#offerbg_colorchange",
//     triggerHook: 0.9, // show, when scrolled 10% into view
//     duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//     offset: 50 // move trigger to center of element
//   })
//   .setClassToggle("#animate_offerbg", "fadeInLeft") // add class to reveal
//   .addIndicators() // add indicators (requires plugin)
//   .addTo(controller);

//   // build scene
//   new ScrollMagic.Scene({
//       triggerElement: "#offerbg_colorchange1",
//       triggerHook: 0.9, // show, when scrolled 10% into view
//       duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//       offset: 50 // move trigger to center of element
//     })
//     .setClassToggle("#animate_offerbg1", "fadeInRight") // add class to reveal
//     .addIndicators() // add indicators (requires plugin)
//     .addTo(controller);

//   new ScrollMagic.Scene({
//       triggerElement: "#offerbg_colorchange2",
//       triggerHook: 0.9, // show, when scrolled 10% into view
//       duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
//       offset: 50 // move trigger to center of element
//     })
//     .setClassToggle("#animate_offerbg2", "fadeInRight") // add class to reveal
//     .addIndicators() // add indicators (requires plugin)
//     .addTo(controller);

// }, 1000);
